import React from "react"
import { Helmet } from "react-helmet"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useIntl } from "gatsby-plugin-intl"

import PublicLayout from "../components/layouts/PublicLayout"
import AppButton from "../components/button"

import Hero from "../images/home_hero.svg"
import FinancialIllustration from "../images/home_automatic_financials.svg"
import ForEveryoneIllustration from "../images/home_for_everyone.svg"
import VideoPoster from "../images/marketing/LaunchPlanHomePoster.png"

const styles = theme => ({
  root: {
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  reverse: {},
  first: {
    paddingTop: 0,
  },
  textImageModule: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `'img' 'text'`,
    alignItems: "center",
    gridGap: "3rem",
    padding: "4rem 1.5rem",
    maxWidth: 1000,
    margin: "0 auto",

    "&$reverse": {
      [theme.breakpoints.up("sm")]: {
        gridTemplateAreas: `'img text'`,
      },
    },

    "&$first": {
      paddingTop: 0,
    },

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `'text img'`,
      gridGap: "5rem",
      padding: "9rem 2rem",
    },

    "& img": {
      gridArea: "img",
      justifySelf: "end",
      maxWidth: 450,
      width: "100%",
    },
  },
  text: {
    gridArea: "text",
    maxWidth: 500,
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  introVideo: {
    textAlign: "center",
    margin: "1rem auto 8rem",
    maxWidth: 500,

    "& iframe": {
      width: 280,
      height: 280,

      [theme.breakpoints.up("sm")]: {
        width: 500,
        height: 500,
      },
    },
  },
})

const HomePage = props => {
  const { classes } = props
  const intl = useIntl()

  return (
    <PublicLayout
      headline={intl.formatMessage({ id: "home_headline" })}
      subheading={intl.formatMessage({ id: "home_subheading" })}
      src={Hero}
      alt={intl.formatMessage({ id: "home_hero_alt" })}
      canonicalUrl="https://launchplan.com"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{intl.formatMessage({ id: "title" })}</title>
        <meta
          name="description"
          content={intl.formatMessage({ id: "description" })}
        />
        <meta
          property="og:title"
          content={intl.formatMessage({ id: "title" })}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <div className={classes.introVideo}>
          <video
            controls
            width="100%"
            height="100%"
            title={intl.formatMessage({ id: "home_video_title" })}
            poster={VideoPoster}
          >
            <source
              src={
                "https://s3-us-west-2.amazonaws.com/launchplan.files/videos/LaunchPlanHome.webm"
              }
              type="video/webm"
            />
            <source
              src={
                "https://s3-us-west-2.amazonaws.com/launchplan.files/videos/LaunchPlanHome.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
        <div
          className={classnames(
            classes.textImageModule,
            classes.borderBottom,
            classes.first
          )}
        >
          <div className={classes.text}>
            <Typography
              component="h6"
              variant="subtitle1"
              className={classes.title}
            >
              {intl.formatMessage({ id: "automated_projections" })}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {intl.formatMessage({ id: "automated_projections_title" })}
            </Typography>
            <div className={classes.separator}></div>
            <Typography gutterBottom className={classes.description}>
              {intl.formatMessage({ id: "automated_projections_description" })}
            </Typography>
            <AppButton
              variant="primary"
              href="/features"
              onClick={() =>
                trackCustomEvent({
                  category: "Home Page",
                  action: "Click",
                  label: "Automatic Financials Features Button",
                })
              }
            >
              {intl.formatMessage({ id: "view_features" })}
            </AppButton>
          </div>
          <img
            src={FinancialIllustration}
            alt={intl.formatMessage({ id: "financial_alt" })}
          />
        </div>
        <div className={classnames(classes.textImageModule, classes.reverse)}>
          <div className={classes.text}>
            <Typography
              component="h6"
              variant="subtitle1"
              className={classes.title}
            >
              {intl.formatMessage({ id: "for_everyone" })}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {intl.formatMessage({ id: "for_everyone_title" })}
            </Typography>
            <div className={classes.separator}></div>
            <Typography gutterBottom className={classes.description}>
              {intl.formatMessage({ id: "for_everyone_description" })}
            </Typography>
            <AppButton
              variant="primary"
              href="/features"
              onClick={() =>
                trackCustomEvent({
                  category: "Home Page",
                  action: "Click",
                  label: "Tell World Features Button",
                })
              }
            >
              {intl.formatMessage({ id: "view_features" })}
            </AppButton>
          </div>
          <img
            src={ForEveryoneIllustration}
            alt={intl.formatMessage({ id: "for_everyone_alt" })}
          />
        </div>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(HomePage)
